import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isMock } from './core/config';
import { AuthService } from './features/auth/services/auth.service';
import { LayoutService } from './shared/services/layout.service';
import { SharedService } from './shared/services/shared.service';
import { SseService } from './shared/services/sse.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'Kharaaj';
  isDemoMode: boolean = isMock;

  public promptEvent;
  public updateAvailable: boolean;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    e.preventDefault();
    this.promptEvent = e;
  }

  public installPWA() {
    this.promptEvent.prompt();
  }

  public shouldInstall(): boolean {
    return !this.isRunningStandalone() && this.promptEvent;
  }

  public isRunningStandalone(): boolean {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  componentDestroyed$ = new Subject<boolean>();
  connectedUser: any;

  constructor(
    private layoutService: LayoutService,
    private authService: AuthService,
    private sharedService: SharedService,
    private sse: SseService,
    private cookieService: CookieService
  ) {
  
  }



  ngOnInit(): void {
    


    this.sharedService
      .getDemoStatus()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((status) => {
        this.isDemoMode = status;
      });
  }

  ngAfterViewInit() {


    if (this.authService.isLoggedIn()) {
      this.layoutService.initializeSidebarListenners();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.complete();
  }
}
