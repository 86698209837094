import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../../auth/services/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private cookieService: CookieService
  ) { }

  clearCacheStorage(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister().then((success) => {
            if (success) {
              console.log('Service worker unregistered successfully.');
            }
          });
        });
      });
    }
    
    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName).then((success) => {
            if (success) {
              console.log(`Cache ${cacheName} deleted successfully.`);
            } else {
              console.warn(`Cache ${cacheName} could not be deleted.`);
            }
          });
        });
      });
    } else {
      console.warn('Cache Storage is not supported in this browser.');
    }
  }

  
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    this.clearCacheStorage()
    this.cookieService.deleteAll();
    sessionStorage.clear();
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    }
 

    let authRequest = req;
    if (this.authService.isLoggedIn()) {
      const authToken = this.authService.getToken();
      authRequest = req.clone({
     
        headers: req.headers.set(
          'authorization',
          'Bearer ' + authToken.toString()
        )
         
      });
      return next.handle(authRequest);
    }

    authRequest = req.clone({
     
      headers: req.headers
       
    });

    return next.handle(authRequest);

  }
}
