<app-navbar></app-navbar>
<div class="container pt-lg-7">
  <div class="row justify-content-center">
    <div class="col-lg-5">
      <div class="login-card">
        <div class="card-header bg-white pb-5"></div>
        <div class="card-body px-lg-5 py-lg-5 mt-3">
          <div class="text-center text-muted text-login">
            <div class="text-login_welcome main-font_light">
              إدخال رمز التفعيل
            </div>
            <div class="text-login_desc">
              <small>الرجاء إدخال رمز التفعيل</small>
            </div>
          </div>
          <div class="phone-number text-center">
            <span class="phone-number_text">
              {{ phoneCode }} {{ phoneNumber }}
            </span>
          </div>
          <form class="login-form" role="form">
            <div class="form-group mb-3">
              <div class="input-group input-group-alternative">
                <input
                  class="form-control"
                  style="letter-spacing: 10px"
                  placeholder="------"
                  type="text"
                  [(ngModel)]="otpCode"
                  pattern="\d*"
                  maxlength="6"
                  (ngModelChange)="onChangeOtpCode()"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
            <div class="text-center">
              <h5 >
                يمكنك طلب رمز الدخول
                <span  class="text-primary fw-800"></span> 
                مرة واحدة فقط كل 30 ثانية
              </h5>
              <a
      
                class="text-primary c-pointer"
                (click)="resendOtp()"
                ><small>إعادة إرسال؟</small></a
              >
            </div>

            <div class="text-center login-form_bottom">
              <button
                type="button"
                class="btn btn-primary my-4"
                (click)="login()"
                [disabled]="!isValid"
              >
                إرسال
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
