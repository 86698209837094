import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { CHART_OPTIONS } from 'src/app/core/const/charts/chart.options';
import { ChartOptions2 } from 'src/app/core/types/charts.types';
import { SharedService } from '../../services/shared.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { BLOB_URL, CURRENCY } from 'src/app/core/config';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/features/private/home/services/home.service';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss']
})
export class CategoryCardComponent implements OnInit, OnDestroy {
  @Input() category;
  chartOptions: ChartOptions2;
  APP_ROUTES = APP_ROUTES;
  BASE_API = BLOB_URL;
  isDemo;
  componentDestroyed$ = new Subject<boolean>();

  showChart = false;

  day;
  isSameDay = true;
  isUp: boolean = true;

  CURRENCY = CURRENCY;

  constructor(
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.chartOptions = CHART_OPTIONS;
    this.cdr.detectChanges()


    this.chartOptions.chart.width = '160%';
    this.chartOptions.chart.height = 220;
    this.sharedService
      .getDemoStatus()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((status) => {
        this.isDemo = status;
      });
    this.getData();

  }

  getData() {
    let numbers = [];
    let dates = [];
    let items = [];
    if (this.category?.transactionsQuantities) {
      this.category?.transactionsQuantities.forEach((transaction) => {

        const item = {
          date: new Date(transaction?.createdAt).toDateString(),
          number: transaction?.transactionQuantity
        };
        items.push(item);

      });
    }


    const groupedData = _.chain(items).groupBy('date').value();
    let finalData = [];
    for (const key in groupedData) {
      const item = {
        date: new Date(key),
        transactions: groupedData[key].map((tr) => tr.number).reduce((a, b) => a + b)
      };
      finalData.push(item);
    };
    finalData = _.orderBy(finalData, 'date', 'asc');
    if (finalData.length) {
      this.day = finalData[0]?.date?.getDay();
    }

    dates = finalData.map((item) => {
      if (item.date.getDay() !== this.day) {
        this.isSameDay = false;
      }
      return item?.date.toDateString();
    });

    numbers = finalData.map((item) => item?.transactions);
    this.isUp = numbers && numbers[0] < numbers[numbers.length - 1];

    this.chartOptions = {
      ...this.chartOptions,
      series: [
        {
          name: this.category?.name,
          data: numbers
        }
      ],
      labels: [...dates]
    }


    this.showChart = true;

  }

  onClickCategory() {
    const route = APP_ROUTES.PRIVATE.DASHBOARD.HOME_CHILDS.SUB_CATEGORY + (this.isDemo ? '1999' : this.category?.id);
    this.router.navigate([route])
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.complete();
    this.category = null;
    this.chartOptions = CHART_OPTIONS;
  }
}
