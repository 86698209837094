<div *ngIf="category" class="card card-profile p-3 shadow-lg rounded-lg" [routerLink]="[
    APP_ROUTES.PRIVATE.DASHBOARD.HOME_CHILDS.SUB_CATEGORY,
    isDemo ? '1999' : category?.id
  ]">
  <!-- Card Header -->
  <div class="card-header d-flex align-items-center">
    <div class="card-image-wrapper">
      <img class="card-image rounded-circle" [src]="BASE_API + category?.attachment?.filename" alt="Category Image" />
    </div>
    <div class="card-title-wrapper ms-3 text-right">
      <h5 class="card-title text-primary fw-bold">{{ category?.name }}</h5>
    </div>
  </div>

  <!-- Chart Section -->
  <div *ngIf="showChart && !isSameDay" class="chart-wrapper mt-4">
    <apx-chart #muuuuu [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke" [dataLabels]="chartOptions.dataLabels" [yaxis]="chartOptions.yaxis"
      [labels]="chartOptions.labels" [title]="chartOptions.title"
      [colors]="['#5ba556', '#42c425', '#7bf96d']"></apx-chart>
  </div>

  <!-- Footer Section -->
  <div class="card-footer mt-4">
    <div class="price-container d-flex justify-content-between">
      <!-- Lowest Price -->
      <div class="price-box">
        <h6 class="price-label">الأقل سعر / كلغ</h6>
        <div class="price-value text-success">
          <ng-container *ngIf="category?.productsLowestPrice !== null; else loader">
            <span>{{ CURRENCY }}</span> {{ category?.productsLowestPrice || '0' }}
          </ng-container>
          <ng-template #loader>
            <div class="loader"></div> <!-- Replace with your loader design -->
          </ng-template>
        </div>

      </div>

      <!-- Highest Price -->
      <div class="price-box">
        <h6 class="price-label">أعلى سعر / كلغ</h6>
        <div class="price-value text-danger">
          <ng-container *ngIf="category?.productsHighestPrice !== null; else loader2">
            <span>{{ CURRENCY }}</span> {{ category?.productsHighestPrice || '0' }}
          </ng-container>
          <ng-template #loader2>
            <div class="loader2"></div> <!-- Replace with your loader design -->
          </ng-template>
        </div>
        
      </div>
    </div>
  </div>
</div>