import { environment } from 'src/environments/environment';

export const BASE_API_URL = environment.API_URL;

export const BLOB_URL = environment.BLOB_URL;

export const INTERNATIONAL_PHONE_CODE = environment.INTERNATIONAL_PHONE_CODE;

export const PHONE_LENGTH = environment.PHONE_LENGTH;

export const MAPS_API_KEY = environment.MAPS_API_KEY;

export const isMock = false;

export const BANK_CONFIG = {
    accountNumber : '59656565111111111',
    ibanNumber : '019222222222222222',
    bankName : 'البنك التونسي السعودي',
    name: 'Kharaaj'
};

export const CURRENCY = 'ريال ';