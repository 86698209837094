import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { APP_ENDPOINTS } from 'src/app/core/endpoints/app.endpoints';
import { categories, category } from 'src/app/core/mock/categories';
import { user } from 'src/app/core/mock/user';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private httpClient: HttpClient) {}

  category: any;
  subCategory: any;

  ticket: any;

  filterProduct(categoryId, params) {
    const queryParams = this.getQueryParams(params);
    return this.httpClient.get(APP_ENDPOINTS.PRODUCTS.PRODUCTS + queryParams);
  }

  getCategories() {
    return this.httpClient.get(APP_ENDPOINTS.CATEGORIES.PARENT); // TODO: Will be changed to .ALL instead .PARENT
  }

  getParentCategoriesSimplified() {
    return this.httpClient.get(APP_ENDPOINTS.CATEGORIES.PARENT_SIMPLE); // TODO: Will be changed to .ALL instead .PARENT
  }
  getSubCategoriesByIdSimplified(id: string) {
    return this.httpClient.get(`${APP_ENDPOINTS.CATEGORIES.ALL}/${id}/sub`);
  }

  getSubCategoriesById(id: string) {
    return this.httpClient.get(`${APP_ENDPOINTS.CATEGORIES.ALL}/${id}/subs/statistics`);
  }

  getSubCategoryStats(id: string) {
    return this.httpClient.get(`${APP_ENDPOINTS.CATEGORIES.ALL}/stats/subs/${id}`);
  }

  getParentCategoryStats(id: string) {
    return this.httpClient.get(`${APP_ENDPOINTS.CATEGORIES.ALL}/stats/parents/${id}`);
  }

  getParentCategories() {
    return this.httpClient.get(APP_ENDPOINTS.CATEGORIES.PARENT);
  }

  getSubCategories() {
    return this.httpClient.get(`${APP_ENDPOINTS.CATEGORIES.PARENT}`); // TODO: Will be changed to .SUB instead .PARENT
  }

  getCategoryById(id: string) {
    return this.httpClient.get(`${APP_ENDPOINTS.CATEGORIES.ALL}/${id}`);
  }

  setCategory(category: any) {
    this.category = category;
  }

  setSubCategory(subCategory: any) {
    this.subCategory = subCategory;
  }

  getCategory() {
    return this.category;
  }

  getSubCategory() {
    return this.subCategory;
  }

  getControllerTickets() {
    return this.httpClient.get('');
  }

  getTicketById(id: string) {}

  setTicket(ticket: any) {
    this.ticket = ticket;
  }

  getTicket() {
    return this.ticket;
  }

  getPairedUsers(params) {
    return this.httpClient.get(APP_ENDPOINTS.USER.PAIRED + params);
  }

  getQueryParams(queryParams) {
    let url = '';
    let i = 0;
    for (const a in queryParams) {
      if (!i) url += '?';
      if (i) url += '&'; 
      url += a + '=' + queryParams[a];
      i++;
    }
    return url;
  }
}
