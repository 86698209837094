<div class="places text-right" [ngClass]="{ 'custom-places': isCustom }">
  <div *ngIf="customPlaces && address" class="places-text mt-3 mb-3">
    <h5 class="fs-18 mt-2">{{ address?.label }}</h5>
  </div>
  <div class="">

    <agm-map [zoom]="8" [latitude]="lat" [longitude]="long">
      <agm-marker *ngFor="let place of places" [latitude]="place?.latitude" [longitude]="place?.longitude">
        <agm-info-window>
          <label class="fs-20 mt-2" (click)="openItinerary(place)">
            {{place?.title}}

          </label>
        </agm-info-window>
      </agm-marker>
    </agm-map>

  </div>
  <div *ngIf="!customPlaces" class="places-text mt-3">
    <div *ngFor="let place of places" class="places_item mt-3">

      <button class="btn btn-primary d-flex align-items-center justify-content-center gap-2"
        (click)="openItinerary(place)">
    
        <i class="fas fa-map-marker-alt"></i>
        {{ place?.title }}
         فتح في خرائط جوجل
      </button>

    </div>
  </div>
</div>